import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

// File imports
import SideHeader from "../../components/SideHeader";
import Header from "../../components/header/HeaderOne";
import Footer from "../../components/footer/FooterThree";
import PersonalPortfolioTeam from "../../container/team";
import BannerPersonalPortfolio from "../../components/HeroBanner/PersonalPortfolio";
import NavigationGallery from '../../components/navgallery';
import CallToAction from '../../components/generic/CallToAction';
import Scrollbar from 'perfect-scrollbar-react';
import 'perfect-scrollbar-react/dist/style.min.css';
import VideoPlayer from '../../components/VideoPlayer';
import imageSrc from '../../assets/images/bruno-adcc.png';
import { NavLink } from "react-router-dom"

const HomeClean = () => {
    const isMobile = window.innerWidth < 992;

    // State and ref for dynamically setting the video height
    const [galleryHeight, setGalleryHeight] = useState(0);
    const galleryRef = useRef(null);

    // Effect to update the height of NavigationGallery
    useLayoutEffect(() => {
        const updateHeight = () => {
            if (galleryRef.current) {
                setGalleryHeight(galleryRef.current.getElementsByClassName('gallery__rectangle-row')[0].offsetHeight);
            }
        };

        // Set the height on initial render
        updateHeight();

        // Update the height on window resize
        window.addEventListener('resize', updateHeight);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [galleryRef]);

    return (
        <div className={'main-wrapper p-0'}>
            <Header classes={'position-static'} />
            <div style={{ display: 'flex', width: '100%', maxHeight: isMobile ? 'auto' : 'calc(100vh - 186px)' }}>
                <Scrollbar options={{ suppressScrollX: true }}>
                    <SideHeader mobile={true} />
                    <div ref={galleryRef}>
                        <NavigationGallery />
                    </div>
                    <div>                   
                        <VideoPlayer
                            src={require('../../assets/videos/atos.mp4')}
                            className="video-player"
                            autoPlay={true}
                            loop={true}
                            muted={true}
                            style={{
                                //  height: `${galleryHeight}px` 
                                width: '100%',       // Ensure the video takes full width
                                height: galleryHeight * 2 || 'auto', // Use passed height or default to 'auto'
                                objectFit: 'cover',   // Crop parts of the video that don't fit
                                objectPosition: 'center', // Center the video content
                                marginBottom: "16px",
                                minHeight: "500px"
    
                            }} // Set dynamic height
                            startTime= {0}
                            endTime= {31}
                            title="BEGIN YOUR JOURNEY TODAY"
                            link="/free-trial"
                        />
                    </div>
                    {/* <CallToAction       style={{
                    backgroundImage: `url(${imageSrc})`,
                    height: galleryHeight * 2,
                    padding: '0'
                }} /> */}
                </Scrollbar>
            </div>
            <Footer position={'relative'} />
        </div>
    );
};

export default HomeClean;
