import React, { useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { motion, useAnimation, useInView } from 'framer-motion';
import serviceBg from '../../../assets/images/logo-transparent.png';
import SectionTitle from "../../../components/SectionTitle";
import Service from "../../../components/service";
import services from '../../../data/service';
import './benefits.scss'; // Ensure you have this SCSS file for styles

const Benefits = () => {
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start('visible');
        }
    }, [isInView, controls]);

    const leftVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: { opacity: 1, x: 0 }
    };

    const rightVariants = {
        hidden: { opacity: 0, x: 100 },
        visible: { opacity: 1, x: 0 }
    };

    const imageVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 0.3 }
    };

    return (
        <div className="section bg-grey section service-bg" ref={ref}>
            <Container>
                <SectionTitle
                    className="title"
                    title={"WHY DO JIU JITSU?"}
                    content={"Train Jiu Jitsu to develop unparalleled physical fitness, mental toughness, and self-defense skills while becoming part of a supportive community."}
                />
                <div className="benefits-grid">
                    <motion.img 
                        src={serviceBg} 
                        initial="hidden"
                        animate={controls}
                        variants={imageVariants}
                        transition={{ duration: 1.5 }}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '450px',
                            height: 'auto',
                            zIndex: -1 // Ensure the image is behind any other content
                        }} 
                        alt="Background"
                    />
                    <motion.div 
                        className="left-column"
                        initial="hidden"
                        animate={controls}
                        variants={leftVariants}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        {services.slice(0, 3).map(service => (
                            <Service
                                key={service.id}
                                title={service.title}
                                content={service.content}
                            />
                        ))}
                    </motion.div>
                    <motion.div 
                        className="right-column"
                        initial="hidden"
                        animate={controls}
                        variants={rightVariants}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        {services.slice(3, 6).map(service => (
                            <Service
                                key={service.id}
                                title={service.title}
                                content={service.content}
                            />
                        ))}
                    </motion.div>
                </div>
            </Container>
        </div>
    );
};

export default Benefits;
