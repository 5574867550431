import React from 'react';
import { Container } from 'react-bootstrap';
import SectionTitle from '../../components/SectionTitle';
import PageSelect from '../../components/PageSelect';
import teams from '../../data/program';

const NavigationGalleryPrograms = () => {
    return (
        <div className="inner-content">
        <div className="section pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50 pb-120 pb-lg-80 pb-md-80 pb-sm-80 pb-xs-50">
            <div className="section-wrap section">
                <Container style={{ padding: '0' }}>
                    <div className="gallery__rectangle-row">
                        {teams.slice(0, 3).map(member => (
                            <div key={member.id} className="gallery__rectangle-item">
                                <PageSelect
                                    className="nav-gallery-inner"
                                    name={member.name}
                                    designation={member.designation}
                                    profilePhoto={member.profilePhoto}
                                    page={member.slug}
                                    socials={member.socials}
                                />
                            </div>
                        ))}
                    </div>
                    {/* <div className="gallery__rectangle-wide">
                        {teams.slice(2, 3).map(member => (
                            <div key={member.id} className="gallery__rectangle-item-wide">
                                <PageSelect
                                
                                    className="nav-gallery-inner"
                                    name={member.name}
                                    designation={member.designation}
                                    profilePhoto={member.profilePhoto}
                                    page={member.slug}
                                    socials={member.socials}
                                />
                            </div>
                        ))}
                    </div> */}
                </Container>
            </div>
        </div>
        </div>
    );
};

export default NavigationGalleryPrograms;
