import React from 'react';
import {Link} from "react-router-dom";

const PortfolioDetailsContent = ({title, slug, category, description, meta}) => {

    const formattedDescription = description.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br/>
        </React.Fragment>
    ));

    return (
        <div className="portfolio-details-content">
            {/* <Link to={`/portfolio/${slug}`} className="category">{category}</Link> */}
            <h1 className="title">{title}</h1>
            <p>{formattedDescription}</p>
            <ul className="project-info">
            </ul>

            <div className="project-share">
                {/* <span>Share:</span> */}
                <a href="/"><i className="fa fa-facebook"/></a>
                <a href="/"><i className="fa fa-twitter"/></a>
                <a href="/"><i className="fa fa-pinterest-p"/></a>
                <a href="/"><i className="fa fa-google-plus"/></a>
            </div>

        </div>
    );
};

export default PortfolioDetailsContent;