import React from 'react';
import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import SectionTitle from '../../components/SectionTitle';
import PageSelect from '../../components/PageSelect';
import teams from '../../data/team';

const NavigationGallery = () => {
    const itemVariants = {
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <div className="section pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50 pb-120 pb-lg-80 pb-md-80 pb-sm-80 pb-xs-50">
            <div className="section-wrap section">
                <Container style={{ padding: '0' }}>
                    <div className="gallery__rectangle-row">
                        {teams.slice(0, 3).map((member, index) => (
                            <motion.div
                                key={member.id}
                                className="gallery__rectangle-item"
                                variants={itemVariants}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 0.5, delay: index * 0.25 }}
                            >
                                <PageSelect
                                    name={member.name}
                                    designation={member.designation}
                                    profilePhoto={member.profilePhoto}
                                    page={member.page}
                                    socials={member.socials}
                                />
                            </motion.div>
                        ))}
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default NavigationGallery;
