import React, {useState, useEffect} from 'react';

// File imported
import SideHeader from "../../components/SideHeader";
import Header from "../../components/header/HeaderOne";
import Footer from "../../components/footer/FooterThree";
import SectionTitle from "../../components/SectionTitle";
import AcademyBanner from '../../components/HeroBanner/AcademyBanner';
import HeroButtons from '../../components/generic/HeroButtons';
import GenericInfoImageRight from '../../components/generic/LeftInfoRightImage';
import GenericInfoImageLeft from '../../components/generic/LeftImageRightInfo';
import ParallaxBackground from '../../components/ParallaxBackground';
import Benefits from '../../components/generic/Benefits';
import Scrollbar from 'perfect-scrollbar-react';
import image1 from '../../assets/images/academy/black-logo.jpg';
import image2 from '../../assets/images/academy/both-classes.jpg';
import VideoPlayer from '../../components/VideoPlayer';

import 'perfect-scrollbar-react/dist/style.min.css';
import SlickCarousel from '../../components/SlickCarousel';
import AcademyImages from '../../data/carousels/academy.json'
import './Academy.scss';


const Academy = () => {
    
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 992);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    const slickSettings = {
      slidesToShow: isMobile ? 1 : 2,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      autoplaySpeed: 3000,
      infinite: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    };

    return (
        <div className={'main-wrapper p-0'}>
            <Header classes={'position-static'}/>
            <div style={{display: 'flex', width: '100%', maxHeight: isMobile ? 'auto' : 'calc(100vh - 186px)'}}>
            <Scrollbar options={{suppressScrollX: true}} style={{overflow:"hidden"}} >
            <SideHeader mobile={true}/>
            {/* <h1>ATOS ATLANTA HQ</h1> */}

            {/* <ParallaxBackground image="/gym-shot-downsample.jpg"></ParallaxBackground> */}
            <HeroButtons/>
            <VideoPlayer
            src={require('../../assets/videos/kids.mp4')}
            className="video-player"
            autoPlay={true}
            loop={true}
            muted={true}
            style={{
                //  height: `${galleryHeight}px` 
                width: '100%',       // Ensure the video takes full width
                height: 700 || 'auto', // Use passed height or default to 'auto'
                objectFit: 'cover',   // Crop parts of the video that don't fit
                objectPosition: 'center', // Center the video content
                marginBottom: "16px",
                minHeight: "500px"

            }} // Set dynamic height
            startTime= {0}
            endTime= {46}
        />

            <Benefits/>
            <VideoPlayer
            src={require('../../assets/videos/nogi.mp4')}
            className="video-player"
            autoPlay={true}
            loop={true}
            muted={true}
            style={{
                //  height: `${galleryHeight}px` 
                width: '100%',       // Ensure the video takes full width
                height: 700 || 'auto', // Use passed height or default to 'auto'
                objectFit: 'cover',   // Crop parts of the video that don't fit
                objectPosition: 'center', // Center the video content
                marginBottom: "16px",
                minHeight: "500px"

            }} // Set dynamic height
            startTime= {3}
            endTime= {34}
        />
            {/* <ParallaxBackground image="/gym-shot-downsample.jpg"></ParallaxBackground> */}
            <GenericInfoImageRight
                title="WORLD CLASS INSTRUCTION"
                text="At Atos Atlanta Academy, our instructors are world-class athletes who bring their experience and passion to every class. Our commitment to excellence ensures you receive the highest level of instruction, empowering you to reach your full potential."
                imageUrl={image1}
                buttonLabel="See Instructors"
                buttonUrl="/instructors"
            />
                        <VideoPlayer
            src={require('../../assets/videos/atos.mp4')}
            className="video-player"
            autoPlay={true}
            loop={true}
            muted={true}
            style={{
                //  height: `${galleryHeight}px` 
                width: '100%',       // Ensure the video takes full width
                height: 700 || 'auto', // Use passed height or default to 'auto'
                objectFit: 'cover',   // Crop parts of the video that don't fit
                objectPosition: 'center', // Center the video content
                marginBottom: "16px",
                minHeight: "500px"

            }} // Set dynamic height
            startTime= {0}
            endTime= {31}
        />
            <GenericInfoImageLeft
                title="A CURRICULUM FOR EVERYONE"
                text="We offer Gi, No-Gi, and kids programs tailored to meet the needs of every student. At Atos Atlanta Academy, our comprehensive curriculum ensures that practitioners of all levels can achieve their personal goals and thrive in their training."
                imageUrl={image2}
                buttonLabel="See Programs"
                buttonUrl="/programs"
            />
                                {/* Set video player height based on galleryHeight */}
            
            <SlickCarousel style={{maxWidth: "100vw", marginTop: "40px"}} imageData={AcademyImages} settings={slickSettings}/>
            <Footer position={'relative'}/>
            </Scrollbar>
            </div>
        </div>
    );
};

export default Academy;
