import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './HeroButtons.scss';
import { motion, useInView, useAnimation } from "framer-motion";
// import MBFreeTrial from '../../MindBody/FreeTrial'; // Import the modal component
import { NavLink } from "react-router-dom"

const HeroButtons = () => {
    const [isDarkMode, setIsDarkMode] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal

    const ref = useRef(null);
    const isInView = useInView(ref, {once: true})

    const mainControls = useAnimation();
    const slideControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        }
    }, [isInView])

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div ref={ref} className='section hero-section'>
            <div className="centered-container">
                <motion.div
                    variants={{
                        hidden: { opacity: 0, y: 75 },
                        visible: { opacity: 1, y: 0 },
                    }}
                    initial="hidden"
                    animate={mainControls}
                    transition={{duration: .5, delay: .25}}
                >
                    <h1>WHERE CHAMPIONS ARE BUILT</h1>
                    <p>
                        Atos Atlanta Academy is a place for personal growth. World champions come together here, creating an environment that encourages you to push your limits. Motivated by a continuous pursuit of excellence, we use Jiu Jitsu to help individuals discover and maximize their potential.
                    </p>
                    <div className="button-group">
                        {/* <button 
                            className={`btn ${isDarkMode ? 'btn-dark' : 'btn-light'}`} 
                            onClick={openModal}
                        >
                            Book Free Trial
                        </button> */}
                        <NavLink to="/free-trial" className={`btn px200 ${isDarkMode ? 'btn-dark' : 'btn-light'}`}>
                        FREE TRIAL
                        </NavLink>
                    </div>
                </motion.div>
            </div>
            {/* <MBFreeTrial isOpen={isModalOpen} closeModal={closeModal} /> Render the modal */}
        </div>
    );
};

export default HeroButtons;
