import React, { useEffect, useState } from 'react';
import Header from "../../components/header/HeaderOne";
import Footer from "../../components/footer/FooterThree";
import SideHeader from "../../components/SideHeader";
import './WeekCalendar.css';

const WeekCalendar = ({ date, onChange, isMobile }) => {
  const [selectedGroup, setSelectedGroup] = useState(null);

  const classSchedule = {
    "MonWedFri": [
      { day: 'Mon/Wed/Fri', classes: [
        { time: '6:00 AM - 7:00 AM', name: 'GI ADVANCED', instructor: 'Prof Bruno' },
        { time: '6:00 AM - 7:00 AM', name: 'GI BASICS', instructor: 'Prof Alex' },
        { time: '7:00 AM - 8:00 AM', name: 'Competition Training', instructor: 'Prof Bruno' },
        { time: '12:00 PM - 1:00 PM', name: 'GI All Levels', instructor: 'Coach Andre' },
        { time: '6:00 PM - 7:15 PM', name: 'Intro to BJJ & GI Basics', instructor: 'Coach Andre' },
        { time: '6:30 PM - 7:30 PM', name: 'GI ADVANCED', instructor: 'Prof Bruno' }
      ]}
    ],
    "TueThu": [
      { day: 'Tue/Thu', classes: [
        { time: '9:00 AM - 10:30 AM', name: 'No GI Fundamentals', instructor: 'Prof Bruno' },
        { time: '12:00 PM - 1:00 PM', name: 'GI All Levels', instructor: 'Coach Andre' },
        { time: '6:00 PM - 7:15 PM', name: 'Intro to BJJ & GI Basics', instructor: 'Coach Phillip' },
        { time: '6:30 PM - 7:30 PM', name: 'GI ADVANCED', instructor: 'Prof Bruno' }
      ]}
    ],
    "Sat": [
      { day: 'Sat', classes: [
        { time: '10:00 AM - 11:00 AM', name: 'GI Situational & Sparring', instructor: 'Prof Bruno' }
      ]}
    ]
  };

  const handleGroupClick = (group) => {
    setSelectedGroup(group);
  };

  return (
    <div className="section schedule-container">
      <div className="week-header button-group">
        <button onClick={() => handleGroupClick("MonWedFri")} className={`btn px100 ${selectedGroup === "MonWedFri" ? 'btn-dark' : 'btn-light'}`}>Mon/Wed/Fri</button>
        <button onClick={() => handleGroupClick("TueThu")} className={`btn  px100 ${selectedGroup === "TueThu" ? 'btn-dark' : 'btn-light'}`}>Tue/Thu</button>
        <button onClick={() => handleGroupClick("Sat")} className={`btn  px100 ${selectedGroup === "Sat" ? 'btn-dark' : 'btn-light'}`}>Sat</button>
      </div>

      <div className="schedule-content">
        {selectedGroup && classSchedule[selectedGroup].map((schedule, index) => (
          <div key={index} className="schedule-day">
            <h3>{schedule.day}</h3>
            {schedule.classes.map((cls, idx) => (
              <div key={idx} className="classItem">
                <span className="classTime"><strong>{cls.time}</strong> </span>
                <span className="className">{cls.name} </span>
                <span className="classInstructor">{cls.instructor}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const SchedulePage = () => {
  const [date, setDate] = useState(new Date());
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={'main-wrapper p-0'}>
      <Header classes={'position-static'}/>
      <SideHeader mobile={true}/>
      <WeekCalendar date={date} onChange={setDate} isMobile={isMobile} />
      <Footer position={'static'}/>
    </div>
  );
};

export default SchedulePage;
