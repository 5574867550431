import React, { useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const VideoPlayer = ({ src, className, autoPlay = true, loop = true, muted = true, style, startTime = 0, endTime, title, link }) => {
  const videoRef = useRef(null);

  // Event handler to loop within the specified time range
  const handleTimeUpdate = () => {
    const video = videoRef.current;
    if (video && endTime && video.currentTime >= endTime) {
      video.currentTime = startTime; // Reset to the start time when the end time is reached
      video.play(); // Continue playing if it's paused
    }
  };

  // Ensure video starts at the defined startTime
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = startTime; // Start the video at the given start time
    }
  }, [startTime]);

  return (
    <div className="section">
      <div className="section-wrap section pb-md-80 pb-sm-80 pb-xs-50">
        <div className="container" style={{ padding: '0' }}>

        {/* if there is a title and link show div below */}
        {title && link && (
            <div style={{position: "absolute", left: "50%",
            top: "65%",
            transform: "translate(-50%, -50%)", zIndex: 1, textAlign: "center", width: "100%", color: "white"
            }}>
                <h2 style={{color: "#ffffffad"}}>BEGIN YOUR JOURNEY TODAY</h2>
                <NavLink to="/free-trial" className="btn btn-light">
                                Free Trial Class
                </NavLink>
                </div>
        )}

          <video
            ref={videoRef}
            className={className}
            src={src}
            autoPlay={autoPlay}
            loop={false} // Disable native loop, since we're handling it manually
            muted={muted}
            playsInline
            style={style}
            onTimeUpdate={handleTimeUpdate} // Track video time updates
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
