import React from 'react';
import { Link } from 'react-router-dom';
import './PageSelect.scss';


const PageSelect = ({name, designation, profilePhoto, socials, page}) => {
    const route = `${process.env.PUBLIC_URL || ''}/${page}`

    return (
        <>
            <Link to={`${page}`} className="nav-gallery-inner">
                <img src={require('../../assets/images/' + profilePhoto)} alt={name} />
                <div className="nav-gallery-content">
                    <h4>{name}</h4>
                </div>
            </Link>
        </>               
    );
};

export default PageSelect;